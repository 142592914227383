import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import { MultiSelect } from 'primereact/multiselect';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../App.scss';
import { AccreditationModal } from '../../components';
import { fetch_accreditations, fetch_search_api, UPDATE_ADDITIONAL_SUBSCRIPTION } from '../../store/modules/batch-accreditation';
import {
  ACCREDITATIONS_URL,
  CREATE_MULTIPLE_ACCREDITED_RESOURCES_URL
} from '../../utils/Constants';
import './batch-accreditation.scss';

const SUBMIT_SUCCESS_MSG = 'Successful';
const SUBMIT_FAILURE_MSG = 'Failed';

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Select all'
  },
  { id: 'title', numeric: true, disablePadding: true, label: 'Module title' }
];

const headCellsAccreditations = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Organisation name'
  },
  { id: 'minutes', numeric: true, disablePadding: false, label: 'Minutes' },
  { id: 'points', numeric: true, disablePadding: false, label: 'Points' },
  {
    id: 'statement',
    numeric: true,
    disablePadding: false,
    label: 'Accreditation statement'
  }
];

const EnhancedTableHead = ({ onSelectAllClick, numSelected, rowCount, headCells }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'default'}
            className="padding-left-zero"
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = ({ numSelected, title }) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {title}
        </Typography>
      )}
    </Toolbar>
  );
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: '#fff'
        }
      : {
          color: '#fff'
        },
  title: {
    flex: '1 1 100%'
  }
}));

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  table: {
    minWidth: 500,
    padding: '6px 24px 6px 0px'
  },
  moduleid: {
    width: 50
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

const CourseAccreditations = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedModules, setSelectedModule] = React.useState([]);
  const [accreditations, setAccreditations] = useState(null);
  const [selectedAccreditations, setSelectedAccreditations] = React.useState([]);
  const [result, setResult] = useState();
  const [search, setSearch] = useState('');
  const [filterModal, setFilterModal] = useState(false);
  const {
    modules,
    additionalSubscriptionsList,
    subscriptionsList,
    data: { accessResources }
  } = useSelector((state) => state.accreditations);

  const additionalSubscriptionsGroup = [
    {
      label: 'Journal',
      items: additionalSubscriptionsList.slice(0, 8)
    },
    {
      label: 'India course',
      items: additionalSubscriptionsList.slice(8, 48)
    },
    {
      label: 'Customer',
      items: additionalSubscriptionsList.slice(48, 52)
    },
    {
      label: 'Collection/Topic',
      items: additionalSubscriptionsList.slice(52, 56)
    },
    {
      label: 'China',
      items: additionalSubscriptionsList.slice(56, 57)
    },
    {
      label: 'BMA',
      items: additionalSubscriptionsList.slice(57, 62)
    },
    {
      label: 'Research to Publication',
      items: additionalSubscriptionsList.slice(62, 63)
    },
    {
      label: 'Standard',
      items: subscriptionsList.slice(0, 1)
    },
    {
      label: 'Free',
      items: subscriptionsList.slice(1, 2)
    }
  ];

  function handleSearch (event) {
    if(event.key === 'Enter') {
      let param = {id: search, language: 'en-gb'};
      dispatch(fetch_accreditations(param));
    }
  }

  function handleChange(value) {
    setSearch(value);
  }

  function handleAdditional(arr) {
    dispatch(UPDATE_ADDITIONAL_SUBSCRIPTION(arr));
    let selectedResources = '';
    arr.forEach((x, index) => selectedResources = index > 0 ? selectedResources + '%7C' + x.code : '?accessResources=' + x.code);
    dispatch(fetch_search_api(selectedResources));
    handleChange('');
  }

  function handleAddFilter () {
    setFilterModal(true);
  }

  function handleCloseModal () {
    console.log('close clicked by me');
    setFilterModal(false);
  }

  const handleSelectAllClickModule = (event) => {
    if (event.target.checked) {
      const selectedModules = modules.map((module) => module.id);
      setSelectedModule(selectedModules);
      return;
    }
    setSelectedModule([]);
  };

  const handleClickModules = (event, name) => {
    const selectedIndex = selectedModules.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedModules, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedModules.slice(1));
    } else if (selectedIndex === selectedModules.length - 1) {
      newSelected = newSelected.concat(selectedModules.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedModules.slice(0, selectedIndex),
        selectedModules.slice(selectedIndex + 1)
      );
    }

    setSelectedModule(newSelected);
  };

  const isSelectedModules = (id) => selectedModules.indexOf(id) !== -1;

  useEffect(() => {
    fetch(ACCREDITATIONS_URL)
      .then((response) => response.json())
      .then((json) => setAccreditations(json))
      .catch((err) => console.error(err));
  }, []);

  const handleSelectAllClickAccreditation = (event) => {
    if (event.target.checked) {
      const selectedAccreditations = accreditationsToDisplay.map((n) => n.id);
      setSelectedAccreditations(selectedAccreditations);
      return;
    }
    setSelectedAccreditations([]);
  };

  const addLeadingZeroToDigit = (num) => {
    return num < 10 ? '0' + num : num;
  };

  const buildAccreditedResourcesObject = () => {
    let currentDateTime = new Date();
    const dd = addLeadingZeroToDigit(currentDateTime.getDate());
    const mm = addLeadingZeroToDigit(currentDateTime.getMonth() + 1);
    const yyyyFrom = currentDateTime.getFullYear();
    const yyyyTo = yyyyFrom + 15;

    const fromDate = yyyyFrom + '-' + mm + '-' + dd;
    const toDate = yyyyTo + '-' + mm + '-' + dd;

    const selectedAccreditationsToBeSaved = accreditations.filter((acc) =>
      selectedAccreditations.includes(acc.id)
    );

    return selectedModules.flatMap((moduleId) =>
      selectedAccreditationsToBeSaved.map((accreditation) => ({
        accreditationId: accreditation.id,
        moduleId: moduleId,
        validFrom: fromDate,
        validTo: toDate,
        language: 'en-gb'
      }))
    );
  };

  const handleSubmitButtonClick = () => {
    const listOfCreateAccreditedResources = buildAccreditedResourcesObject();
    postAccreditedResources(listOfCreateAccreditedResources);
  };

  const postAccreditedResources = (data) => {
    fetch(CREATE_MULTIPLE_ACCREDITED_RESOURCES_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((response) => {
        if (response.ok) {
          setResult(SUBMIT_SUCCESS_MSG);
        } else {
          setResult(SUBMIT_FAILURE_MSG);
        }
      })
      .catch(() => {
        setResult(SUBMIT_FAILURE_MSG);
      });
  };

  const handleClickAccreditations = (event, id) => {
    const selectedIndex = selectedAccreditations.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedAccreditations, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedAccreditations.slice(1));
    } else if (selectedIndex === selectedAccreditations.length - 1) {
      newSelected = newSelected.concat(selectedAccreditations.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedAccreditations.slice(0, selectedIndex),
        selectedAccreditations.slice(selectedIndex + 1)
      );
    }
    setSelectedAccreditations(newSelected);
  };

  const isSelectedAccreditations = (id) => selectedAccreditations.indexOf(id) !== -1;

  const [filterByCpdMinutesKeyword, setFilterByCpdMinutesKeyword] = useState('');
  const accreditationsToDisplay =
    filterByCpdMinutesKeyword !== ''
      ? accreditations.filter(
          (accreditation) => accreditation.cpdMinutes.toString() === filterByCpdMinutesKeyword
        )
      : accreditations;

  const handleFilterByCpdMinutes = (e) => {
    setFilterByCpdMinutesKeyword(e.target.value);
  };
  return (
    <main className="batch-accreditation">
      <Grid container className="batch-acc">
        <Grid container>
          <Typography variant="h2" component="h2">
            Batch Accreditation
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={6} sm={4}>
            <div className="search relative inline-block">
            <input
              className="search-input"
              value={search}
              placeholder="Search by Course ID"
              onChange={({ target: { value } }) => handleChange(value)}
              onKeyDown={(event) => handleSearch(event)}
            />
            <i className="absolute">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
                <path
                  d="M9.9123 1.45095C12.2923 3.49774 12.6923 7.03303 10.8299 9.55987L14.6399 13.3699C14.9521 13.7264 14.9343 14.264 14.5991 14.5991C14.264 14.9343 13.7264 14.9521 13.3699 14.6399L9.55987 10.8299C7.03303 12.6923 3.49774 12.2923 1.45095 9.9123C-0.595849 7.53235 -0.462252 3.97701 1.75738 1.75738C3.97701 -0.462252 7.53235 -0.595849 9.9123 1.45095ZM1.7998 6.00987C1.7998 8.32947 3.68021 10.2099 5.9998 10.2099C7.11371 10.2099 8.182 9.76737 8.96965 8.97972C9.75731 8.19207 10.1998 7.12378 10.1998 6.00987C10.1998 3.69028 8.3194 1.80987 5.9998 1.80987C3.68021 1.80987 1.7998 3.69028 1.7998 6.00987Z"
                  fill="#2A6EBB"
                />
              </svg>
            </i>
          </div>
          </Grid>
          <Grid item xs={6} sm={2}>
          <button
              className='filter-btn'
              onClick={
                () => handleAddFilter()
              }
            >
              Add filter
            </button>
          </Grid>
          
          <Grid item xs={12} sm={6} className="filter">
            <h3>Filter by cpd minutes</h3>
            <input onChange={handleFilterByCpdMinutes} type="number" />
          </Grid>
        </Grid>
        <Grid className={classes.root} md="6">
          <Paper className="table-container">
            <EnhancedTableToolbar numSelected={selectedModules.length} title="Modules" />
            <TableContainer style={{ maxHeight: 550 }}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  numSelected={selectedModules.length}
                  onSelectAllClick={handleSelectAllClickModule}
                  rowCount={modules && modules.length}
                  headCells={headCells}
                />
                <TableBody>
                  {modules &&
                    modules.map((module, index) => {
                      const isItemSelected = isSelectedModules(module.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={module.name}
                          selected={isItemSelected}
                          onClick={(event) => handleClickModules(event, module.id)}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {module.id}
                          </TableCell>
                          <TableCell align="left">{module.title}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {!modules && (
            <div class="loadingModules">
              <CircularProgress color="inherit" thickness={3} size={25} />
            </div>
          )}
        </Grid>
        <Grid md="6">
          <Paper className="table-container2">
            <EnhancedTableToolbar
              numSelected={selectedAccreditations.length}
              title="Accreditations"
            />
            <TableContainer style={{ maxHeight: 550 }}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selectedAccreditations.length}
                  onSelectAllClick={handleSelectAllClickAccreditation}
                  rowCount={accreditationsToDisplay && accreditationsToDisplay.length}
                  headCells={headCellsAccreditations}
                />
                <TableBody>
                  {accreditationsToDisplay &&
                    accreditationsToDisplay.map((accreditation, index) => {
                      const isItemSelected = isSelectedAccreditations(accreditation.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={accreditation.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={(event) =>
                                handleClickAccreditations(event, accreditation.id)
                              }
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {accreditation.organisation.name}
                          </TableCell>
                          <TableCell>{accreditation.cpdMinutes}</TableCell>
                          <TableCell>{accreditation.cpdPoints.toFixed(2)}</TableCell>
                          <TableCell align="left">{accreditation.accreditationStatement}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {!accreditations && (
            <div class="loading">
              <CircularProgress color="inherit" thickness={3} size={25} />
            </div>
          )}
        </Grid>
        <Grid container>
          <div className="result">
            <Button
              variant="contained"
              className="primary-button"
              onClick={() => handleSubmitButtonClick()}
              disabled={selectedModules.length === 0 || selectedAccreditations.length === 0}
            >
              Apply accreditations
            </Button>
            <Typography variant="h3" component="h3">
              {result}
            </Typography>
          </div>
        </Grid>
      </Grid>
      {filterModal && (
        <AccreditationModal
          handleClose={() => handleCloseModal()}  
        >
          <div className="filter-selector">
          <div className="select-subscription">
          <div id="bmj-journals-subscriptions" className="relative m-t-24">
          <label htmlFor="additional" cy="additional-subscriptions">
          <b>Additional subscriptions</b>
          <MultiSelect value={accessResources}
                       options={additionalSubscriptionsGroup}
                       onChange={(e) => handleAdditional(e.value)}
                       optionLabel="displayName"
                       optionGroupLabel="label"
                       optionGroupChildren="items"
                       placeholder="Search all other subscriptions"
                       display="chip"
                       filter
                       resetFilterOnHide />
          </label>
      </div>
          </div>
          </div>
        </AccreditationModal>
      )}
    </main>
  );
};
export default CourseAccreditations;
